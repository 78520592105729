<template>
  <div class="mb-10">
    <!--begin::Heading-->
    <h2 class="anchor fw-bolder mb-5">
      <a href="#column-offset"></a>
      Column offset
    </h2>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">You can specify column offsets.</div>
    <!--end::Block-->

    <div class="rounded border p-10">
      <el-row :gutter="20">
        <el-col :span="6"><div class="grid-content bg-purple"></div></el-col>
        <el-col :span="6" :offset="6"
          ><div class="grid-content bg-purple"></div
        ></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" :offset="6"
          ><div class="grid-content bg-purple"></div
        ></el-col>
        <el-col :span="6" :offset="6"
          ><div class="grid-content bg-purple"></div
        ></el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="6"
          ><div class="grid-content bg-purple"></div
        ></el-col>
      </el-row>

      <CodeHighlighter :field-height="400" lang="html">{{
        code4
      }}</CodeHighlighter>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>

<script>
import { defineComponent } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";
import { code4 } from "@/view/pages/resources/documentation/element-ui/basic/layout/code.ts";

export default defineComponent({
  name: "column-offset",
  components: {
    CodeHighlighter,
  },
  setup() {
    return {
      code4,
    };
  },
});
</script>
